<template>
  <section class="Services">
    <div class="Container">
      <h1 class="Title-h3 Services-Title">Services</h1>
      <div class="Services-List">
        <div
            class="Services-Item"
            v-for="(info, index) in listInfo"
            :key="index"
        >
          <img :src="require(`@/assets/images/${info.img}`)" alt="" class="Services-ItemImg">
          <div class="Services-ItemTitle">{{ info.title }}</div>
          <p class="Text" v-html="info.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      listInfo: [
        {
          img: "megafon.svg",
          title: "Lead Nurture Marketing",
          text: "Beef up your digital marketing funnel through high-quality push marketing on popular, relevant social media platforms.<br/><br/>A successful lead nurturing program focuses marketing and communication efforts on listening to the needs of prospects and providing the information and answers they need to build trust, increase brand awareness, and maintain a connection until prospects are ready to make a purchase."
        },
        {
          img: "rocket.svg",
          title: "Website Optimization",
          text: "Increase the conversion rates on your website and landing pages to get more revenue from the site traffic you’re already getting.<br/><br/>Optimizing your website helps you gain your visitors’ trust, starts building a relationship, and lets you sell products without having to jump on a sales call.<br/><br/>When you do optimize for search, you put yourself on the map. Consumers will search for relevant terms, and your website and business will always show up."
        },
        {
          img: "diamond.svg",
          title: "Creative Services",
          text: "Creative assets can make or break your campaign.<br/><br/>Our department does creative work such as writing, designing and production.<br/><br/>Our in-house copywriters have written thousands of web pages for contractors and target the right keywords to make your website more visible on search engine results pages.",
        },
        {
          img: "bar.svg",
          title: "DIGITAL COMPETITIVE ANALYSIS",
          text: "With a digital competitive analysis from Digitalius, you'll get a market analysis of your website and three competitors.<br/><br/>This provides a clear path to measurably improve your website's SEO and PPC performance and generate more leads and sales.<br/><br/>With a thorough competitive analysis from Blue Corona, we will identify the Strengths, Weaknesses, Threats, and Opportunities from a digital marketing perspective—including actionable insights you can take to close any gaps and get ahead of your competition. We’ll provide custom-tailored recommendations for each section above in how you can improve your online presence and performance."
        },
        {
          img: "mail.svg",
          title: "EMAIL MARKETING",
          text: "Our professional email marketers help your business stay top-of-mind with your prospects and existing customers by sending custom email newsletters every month.<br/><br/>Our team handles the design, copywriting, and your customer list segmentation while you generate more leads and sales.<br/><br/>When done right, email marketing campaigns are simple, efficient, cost-effective, and read by relevant eyes. Opt-in email marketing, drip email marketing, and marketing automation from Digitalius are a low-cost way to increase referrals, up-sells, leads, and booked jobs (sales)!"
        },
        {
          img: "darts.svg",
          title: "Paid Advertising",
          text: "We integrate your platform tracking, marketing analytics and CRM with our end-to-end tracking solution to drive tangible business value for our clients.<br/><br/>When you are running paid advertising campaigns, you are able to reach a very broad and large audience.<br/><br/>Once a visitor has browsed your website or clicked through an ad, you can run a retargeting campaign to continuously engage prospects who have shown an interest in your product or service. By doing so, you are constantly in the back of the mind of your potential customers."
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Services {
  padding: 200px 0 150px;

  &-Title {
    margin-bottom: 52px;
    padding-left: 80px;
    color: var(--color-text-main2);
  }

  &-List {

  }

  &-Item {
    margin-bottom: 30px;
    padding: 40px 32px;
    border-radius: 4px;
    background-color: var(--color-text-main3);
    box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-ItemImg {
    margin-bottom: 30px;
  }

  &-ItemTitle {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.23;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}
</style>