<template>
  <Services/>
</template>

<script>
import Services from "@/components/Services";

export default {
  name: "ServicesPage",
  components: {Services}
}
</script>

<style scoped>

</style>