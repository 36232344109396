<template>
  <section class="About">
    <div class="About-Header">
      <div class="Container">
        <h1 class="Title--h1 About-HeaderTitle">
          We are digital marketers who care about you and your success
        </h1>
      </div>
    </div>
    <div class="About-About">
      <div class="Container About-Container">
        <h3 class="Title-h3 About-AboutTitle">Meet your marketing team</h3>
        <p class="Text About-AboutText">
          Marketing has changed more in the past 10 years than it had in the previous
          100—launching us out of the age of TV spots and print ads and into the age of websites, online video, social
          media, and Google. Consumers today are connected to the web 24/7—U.S. adults now spend upwards of 6 hours per
          day consuming digital media, and that doesn’t count those whose jobs are internet-dependent.
        </p>
        <div class="About-AboutList">
          <div class="About-AboutItem">
            <div class="About-AboutItemDigit">10+</div>
            <div class="About-AboutItemText">years in business</div>
          </div>
          <div class="About-AboutItem">
            <div class="About-AboutItemDigit">76%</div>
            <div class="About-AboutItemText">return clients</div>
          </div>
          <div class="About-AboutItem">
            <div class="About-AboutItemDigit">1.2M</div>
            <div class="About-AboutItemText">hours of expertise</div>
          </div>
          <div class="About-AboutItem">
            <div class="About-AboutItemDigit">1,054</div>
            <div class="About-AboutItemText">websites launched</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped lang="scss">
.About {
  padding: 112px 0 87px;

  &-Header {
    padding: 90px 0;
    background-image: url(~@/assets/images/about-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: $screen-l) {
      background-image: url(~@/assets/images/about-bg-1920.png);
      padding: 90px 110px;
    }
  }

  &-HeaderTitle {
    width: 280px;
    margin: 0 auto;

    @media (min-width: $screen-l) {
      width: 650px;
    }

    @media (min-width: $screen-xl) {
      width: 950px;
    }
  }

  &-About {
    padding: 78px 0 0;
  }

  &-Container {
    width: 280px;
    padding: 0;

    @media (min-width: $screen-l) {
      width: 650px;
    }

    @media (min-width: $screen-xl) {
      width: 950px;
    }
  }

  &-AboutTitle {
    margin-bottom: 18px;
    color: var(--color-text-main2);
  }

  &-AboutText {
    margin-bottom: 40px;
  }

  &-AboutList {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px 0;

    @media (min-width: $screen-l) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-AboutItemDigit {
    font-weight: 600;
    font-size: 64px;
    line-height: 1.23;
    color: var(--color-text-yellow-light);
  }
  &-AboutItemText {
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>