<template>
  <About/>
</template>

<script>
import About from "@/components/About";

export default {
  name: "AboutPage",
  components: {About}
}
</script>

<style scoped>

</style>